import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Cambiar Switch a Routes
import SportsUnits from './components/SportsUnits';
import Courts from './components/Courts';
import Videos from './components/Videos';
import Hours from './components/Hours'; // Importa el nuevo componente

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<SportsUnits />} />
          <Route exact path="/unit/:unit" element={<Courts />} />
          <Route exact path="/hours/:unit/:court" element={<Hours />} /> {/* Nueva ruta */}
          <Route exact path="/videos/:unit/:court/:hour/:day" element={<Videos />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

