import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';

const Hours = () => {
  const { unit, frontName, court } = useParams();
  const [videosByDateTime, setVideosByDateTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const { unitName, courtName, unitStateLogo } = location.state || {};

  const calculateTimeRange = () => {
    const now = new Date();
    const endTime = now.toISOString().replace(/[-:T.Z]/g, '').slice(0, 12); // Hora actual en formato YYYYMMDDHHMM
    const startTime = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000) // Hace 7 días
      .toISOString()
      .replace(/[-:T.Z]/g, '')
      .slice(0, 12); // Hora de hace una semana
    return { startTime, endTime };
  };

  const groupVideosByDateTime = (videos) => {
    const grouped = videos.reduce((acc, video) => {
      const videoKey = video.SK; // Usamos SK como referencia
      const year = videoKey.substring(0, 4);
      const month = videoKey.substring(4, 6);
      const day = videoKey.substring(6, 8);
      const hour = videoKey.substring(8, 10);

      const dateTime = `${year}-${month}-${day}T${hour}:00Z`;

      if (!acc[dateTime]) acc[dateTime] = [];
      acc[dateTime].push(video);
      return acc;
    }, {});

    return Object.entries(grouped)
      .sort(([a], [b]) => new Date(b) - new Date(a)); // Orden descendente
  };

  useEffect(() => {
    // Verificar si hay datos en caché
    const cachedVideos = localStorage.getItem(`videos_${frontName}_${court}`);
    if (cachedVideos) {
      const parsedVideos = JSON.parse(cachedVideos);
      setVideosByDateTime(groupVideosByDateTime(parsedVideos));
      setLoading(false);
    }

    const fetchVideos = async () => {
      try {
        const { startTime, endTime } = calculateTimeRange(); // Calcula el rango de fechas
        const response = await fetch(`${backendUrl}/recordings/${court}?startTime=${startTime}&endTime=${endTime}`, {
          method: 'GET', // Método HTTP (opcional aquí porque 'GET' es predeterminado)
          headers: {
            'Content-Type': 'application/json', // Especifica que el contenido es JSON
            'x-api-key': process.env.REACT_APP_API_KEY, // Incluye la API key
          },
        });
        const data = await response.json();

        // Si los datos del backend son diferentes a los del caché, actualizamos el estado y el caché
        if (JSON.stringify(data) !== cachedVideos) {
          setVideosByDateTime(groupVideosByDateTime(data));
          localStorage.setItem(`videos_${frontName}_${court}`, JSON.stringify(data)); // Guardar en caché
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [frontName, court, backendUrl]);

  const formatDate = (videoKey) => {
    const year = videoKey.substring(0, 4);
    const month = videoKey.substring(4, 6);
    const day = videoKey.substring(6, 8);
    const hour = videoKey.substring(8, 10);

    const utcDate = new Date(`${year}-${month}-${day}T${hour}:00Z`);
    // Convertir a hora local
    const localDate = new Date(utcDate);
    const formattedDate = localDate.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    const localHour = localDate.getHours(); // Hora local
    const nextHour = (localHour + 1) % 24; // Próxima hora (maneja el cambio de día)

    return `${formattedDate} ${localHour}:00 - ${nextHour}:00`;
  };

  if (loading && videosByDateTime.length === 0) {
    return (
      <div className="loader-container">
        <div className="loader">
          <img src="/logo-no-background.png" alt="Grabala Logo" />
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <header
        style={{
          backgroundColor: '#FF0000',
          width: '100%',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <Link to="/">
          <img
            src="/logo-no-background.png"
            alt="Grábala Logo"
            style={{ paddingTop: '10px', height: '70px' }}
          />
        </Link>
      </header>
      <div style={{ paddingTop: '80px' }}>
        <h1>{unitName}</h1>
        <h2>{courtName}</h2>
        <div className="button">
          {videosByDateTime.map(([dateTime, videos]) => (
            <button
              key={dateTime}
              onClick={() =>
                navigate(`/videos/${unit}/${court}/${new Date(dateTime).getHours()}/${new Date(dateTime).getDay()}`, {
                  state: { unitName, courtName, date: dateTime, unitStateLogo },
                })
              }
              className="hour-button"
            >
              {formatDate(videos[0].SK)}
            </button>
          ))}
        </div>

        <Link to={`/unit/${unit}`} state={[unitName, unitStateLogo]}>
          <button>Volver</button>
        </Link>
      </div>
    </div>
  );
};

export default Hours;
