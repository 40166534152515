import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';

const Videos = () => {
  const { unit, court, hour } = useParams();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const cdnUrl = process.env.REACT_APP_CDN_RECORDINGS_URL; // URL del CDN
  const location = useLocation();
  const { unitName, courtName, date, unitStateLogo } = location.state || {};
  const [loadingShare, setLoadingShare] = useState({}); // Almacena el estado de carga para cada video

  useEffect(() => {
    const calculateTimeRange = (dateTime) => {
      const baseDate = new Date(dateTime);
      if (isNaN(baseDate.getTime())) {
        console.error("Invalid Date: Check the format of dateTime");
        return;
      }

      const startHourUTC = baseDate.getUTCHours();
      const startDate = new Date(baseDate);
      startDate.setUTCHours(startHourUTC, 0, 0, 0);
      const endDate = new Date(startDate);
      endDate.setUTCHours(startHourUTC + 1);

      const startTime = startDate.toISOString().replace(/[-:T.Z]/g, '').slice(0, 12);
      const endTime = endDate.toISOString().replace(/[-:T.Z]/g, '').slice(0, 12);

      return { startTime, endTime };
    };

    const fetchVideos = async () => {
      try {
        const { startTime, endTime } = calculateTimeRange(date);
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/recordings/${court}?startTime=${startTime}&endTime=${endTime}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_API_KEY,
            },
          }
        );
        const data = await response.json();
        setVideos(data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [unit, court, hour, date]);

  const handleDownload = (videoName) => {
    const link = document.createElement('a');
    link.href = `${cdnUrl}/${unit}/${court}/${videoName}`;
    link.download = videoName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShare = async (videoName) => {
    const videoUrl = `${cdnUrl}/${unit}/${court}/${videoName}`;
    setLoadingShare((prev) => ({ ...prev, [videoName]: true })); 
    try {
      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const file = new File([blob], videoName, { type: blob.type });

      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          files: [file],
          title: `Video de ${courtName}`,
          text: '¡Mira este video increíble!',
        });
        console.log('Video compartido con éxito');
      } else {
        console.error('La API Web Share no está disponible o no admite compartir archivos.');
        alert('Tu navegador no soporta compartir archivos.');
      }
    } catch (error) {
      if (error.name === 'AbortError' || error.message.includes('The user aborted')) {
        console.log('El usuario cerró la ventana de compartir sin completar la acción.');
      } else {
        console.error('Error al compartir el video:', error);
        alert('Error al compartir el video. Inténtalo más tarde.');
      }
    } finally {
      setLoadingShare((prev) => ({ ...prev, [videoName]: false })); // Desactivar loader para este video
    }
  };

  const formatDate = (videoKey) => {
    const year = videoKey.substring(0, 4);
    const month = videoKey.substring(4, 6);
    const day = videoKey.substring(6, 8);
    const hour = videoKey.substring(8, 10);
    const minute = videoKey.substring(10, 12) || '00';
    const videoDate = new Date(`${year}-${month}-${day}T${hour}:${minute}Z`);

    const now = new Date();
    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);

    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const localHour = videoDate.getHours();
    const localMinute = String(videoDate.getMinutes()).padStart(2, '0');

    if (videoDate.toDateString() === now.toDateString()) {
      return `Hoy a las ${localHour}:${localMinute}`;
    } else if (videoDate.toDateString() === yesterday.toDateString()) {
      return `Ayer a las ${localHour}:${localMinute}`;
    } else {
      return `${videoDate.toLocaleDateString('es-ES', options)} a las ${localHour}:${localMinute}`;
    }
  };

  if (loading && !videos.length) {
    return (
      <div className="loader-container">
        <div className="loader">
          <img src="/logo-no-background.png" alt="Grabala Logo" />
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <header
        style={{
          backgroundColor: '#FF0000',
          width: '100%',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <Link to="/">
          <img src="/logo-no-background.png" alt="Grábala Logo" style={{ paddingTop: '10px', height: '70px' }} />
        </Link>
      </header>
      <div style={{ paddingTop: '80px' }}>
        <h1>{unitName} - {courtName}</h1>
        <h2>Videos de {hour}:00 - {parseInt(hour) + 1}:00</h2>
        <div className="videos-container">
          {videos.map((video, index) => {
            const formattedDate = formatDate(video.SK);
            return (
              <div key={index} className="video-card">
                <video width="400" controls>
                  <source src={`${cdnUrl}/${unit}/${court}/${video.name}`} type="video/mp4" />
                </video>
                <p>{formattedDate}</p>
                <div className="action-buttons">
                  <button className="round-button" onClick={() => handleDownload(video.name)}>
                    <img src="/icons/download-icon.png" alt="Descargar" />
                  </button>
                  <button className="round-button" onClick={() => handleShare(video.name)}>
                      {loadingShare[video.name] ? (
                        <img src="/icons/loader.gif" alt="Cargando" />
                      ) : (
                        <img src="/icons/share-icon.png" alt="Compartir" />
                      )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Link to={`/hours/${unit}/${court}`} state={{ unitName, courtName, unitStateLogo }}>
        <button>Volver</button>
      </Link>
    </div>
  );
};

export default Videos;

