import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const SportsUnits = () => {
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(''); // Estado para la búsqueda
  const [filteredUnits, setFilteredUnits] = useState([]); // Estado para las unidades filtradas
  const backendUrl = process.env.REACT_APP_BACKEND_URL; // Obtener la URL desde la variable de entorno
  const cityId = process.env.REACT_APP_CITY_ENS; // Obtener el ID de la ciudad desde el archivo .env
  const cdnAssetsUrl = process.env.REACT_APP_CDN_ASSETS_URL; // URL del CDN

  useEffect(() => {
    const cachedUnits = localStorage.getItem('sports_units');

    if (cachedUnits) {
      const parsedUnits = JSON.parse(cachedUnits);
      setUnits(parsedUnits);
      setFilteredUnits(parsedUnits); // Inicializar con las unidades desde caché
      setLoading(false);
    }

    const fetchUnits = async () => {
      try {
        //const response = await fetch(`${backendUrl}/sports-units/city/${cityId}`);

        const response = await fetch(`${backendUrl}/sports-units/city/${cityId}`, {
          method: 'GET', // Método HTTP (opcional aquí porque 'GET' es predeterminado)
          headers: {
            'Content-Type': 'application/json', // Especifica que el contenido es JSON
            'x-api-key': process.env.REACT_APP_API_KEY, // Incluye la API key
          },
        });

        const data = await response.json();
        if (JSON.stringify(data) !== cachedUnits) {
          setUnits(data);
          setFilteredUnits(data); // Inicializar las unidades filtradas
          localStorage.setItem('sports_units', JSON.stringify(data));
        }
      } catch (error) {
        console.error('Error fetching sports units:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUnits();
  }, [backendUrl, cityId]);

  // Función que actualiza el estado de búsqueda y filtra las unidades
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredUnits(
      units.filter((unit) => unit.name.toLowerCase().includes(query))
    );
  };

  if (loading && !units.length) {
    return (
      <div className="loader-container">
        <div className="loader">
          <img src="/logo-no-background.png" alt="Grabala Logo" />
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <header
        style={{
          backgroundColor: '#FF0000',
          width: '100%',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <Link to="/">
          <img
            src="/logo-no-background.png"
            alt="Grábala Logo"
            style={{ paddingTop: '10px', height: '70px' }}
          />
        </Link>
      </header>

      {/* Contenido después del header */}
      <div
        style={{
          paddingTop: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          minHeight: 'calc(100vh - 70px)',
        }}
      >
        <h1>Clubes Deportivos</h1>

        <input
          type="text"
          placeholder="Buscar club..."
          value={searchQuery}
          onChange={handleSearch}
          style={{
            padding: '10px',
            width: '300px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            backgroundColor: '#E0E0E0',
            color: '#808080',
            fontFamily: 'Gugi',
            textAlign: 'left',
          }}
          className="search-input"
        />

        <div
          className="logo-container"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '20px',
            marginTop: '0px',
          }}
        >
          {filteredUnits.map((unit, index) => {
            return (
              <Link
                key={index}
                to={`/unit/${unit.SK.replace('UNIT#', '')}`}
                state={[unit.name, unit.logo]}
              >
                <img
                  className="unit-logo"
                  src={`${cdnAssetsUrl}/${cityId}/${unit.SK.replace('UNIT#', '')}/${unit.logo}`}
                  alt={unit.name}
                  style={{ maxWidth: '200px', maxHeight: '200px' }}
                />
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SportsUnits;
